import {Carousel} from 'antd';
import '@/styles/home.scss'

export default function Home() {
  return <div className='home home-page'>
    <Carousel autoplay autoplaySpeed={10000} swipe={false}>
      {
        [
          {"name": "YYTM.intro.005.jpeg"},
          {"name": "YYTM.intro.009.jpeg"},
          {"name": "YYTM.intro.021.jpeg"},
          {"name": "YYTM.intro.035.jpeg"},
          {"name": "YYTM.intro.043.jpeg"},
          {"name": "YYTM.intro.046.jpeg"}
        ].map(e => <div key={e.name}><img src={`/res/home/${e.name}`}/></div>)
      }
    </Carousel>
  </div>
}
