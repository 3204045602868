import React, {useState} from 'react'
import {Link} from "react-router-dom";
import '@/styles/nav.scss'
import menu from '@/menu'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {showNav} from "@/lib/redux";

function Nav({show, dispatch}) {
  let location = useLocation();
  let headerCls = [], navCls = [];
  let Icon = show.nav ? MenuUnfoldOutlined : MenuFoldOutlined;
  if (!show.header) headerCls.push('hidden')
  if (location.pathname == '/') headerCls.push("home-page")
  if (show.nav) navCls.push('expanded')
  return <>
    <span className={`header-expander ${navCls.join(" ")}`} onClick={() => dispatch(showNav(!show.nav))}>
      <Icon style={{"fontSize": '24px'}}/>
    </span>
    <header className={`${headerCls.join(" ")}`}>
      <a className='logo-container' href="/">
        <img src="/res/logo/YIYUANTANG-logo-w.png" style={{height: "35px", verticalAlign: 'middle'}}/>
      </a>
    </header>
    <nav className={`${navCls.join(" ")}`}>
      <ul>
        {menu.map(item => <li key={item.name}>
          <Link to={"/" + item.url}>{item.name}</Link>
        </li>)}
      </ul>
    </nav>
  </>
}

export default connect(
  state => ({
    show: state.layout.show,
  })
)(Nav)
