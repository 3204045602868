import React from 'react'
import '@/styles/MainContent.scss'
import {showHeader, showNav} from "@/lib/redux";
import {connect} from "react-redux";
import {debounce} from "@/lib/util";

const ImageInfos = [
  {
    "name": "1.1_Introduction", "contents": [
      {"name": "YYTM.intro.002.jpeg"}
    ]
  }, {
    "name": "1.2_The_YYT_collection", "contents": [
      {"name": "YYTM.intro.003.jpeg"},
      {"name": "YYTM.intro.004.jpeg"},
      {"name": "YYTM.intro.005.jpeg"},
      {"name": "YYTM.intro.006.jpeg"},
      {"name": "YYTM.intro.007.jpeg"}
    ]
  }, {
    "name": "1.3_Art_sites", "contents": [
      {"name": "YYTM.intro.008.jpeg"},
      {"name": "YYTM.intro.009.jpeg"},
      {"name": "YYTM.intro.010.jpeg"},
      {"name": "YYTM.intro.011.jpeg"},
      {"name": "YYTM.intro.012.jpeg"},
      {"name": "YYTM.intro.013.jpeg"},
      {"name": "YYTM.intro.014.jpeg"},
      {"name": "YYTM.intro.015.jpeg"},
      {"name": "YYTM.intro.016.jpeg"}
    ]
  }, {
    "name": "1.4_Exhibition", "contents": [
      {"name": "YYTM.intro.017.jpeg"},
      {"name": "YYTM.intro.018.jpeg"},
      {"name": "YYTM.intro.019.jpeg"},
      {"name": "YYTM.intro.020.jpeg"},
      {"name": "YYTM.intro.021.jpeg"},
      {"name": "YYTM.intro.022.jpeg"},
      {"name": "YYTM.intro.023.jpeg"},
      {"name": "YYTM.intro.024.jpeg"},
      {"name": "YYTM.intro.025.jpeg"}
    ]
  }, {
    "name": "1.5_Public_art", "contents": [
      {"name": "YYTM.intro.029.jpeg"},
      {"name": "YYTM.intro.030.jpeg"},
      {"name": "YYTM.intro.031.jpeg"},
      {"name": "YYTM.intro.032.jpeg"},
      {"name": "YYTM.intro.033.jpeg"},
      {"name": "YYTM.intro.034.jpeg"},
      {"name": "YYTM.intro.035.jpeg"},
      {"name": "YYTM.intro.036.jpeg"},
      {"name": "YYTM.intro.037.jpeg"}
    ]
  }, {
    "name": "1.6_R_D", "contents": [
      {"name": "YYTM.intro.038.jpeg"},
      {"name": "YYTM.intro.039.jpeg"},
      {"name": "YYTM.intro.040.jpeg"},
      {"name": "YYTM.intro.041.jpeg"},
      {"name": "YYTM.intro.042.jpeg"},
      {"name": "YYTM.intro.043.jpeg"}
    ]
  }, {
    "name": "1.7_Charity", "contents": [
      {"name": "YYTM.intro.044.jpeg"},
      {"name": "YYTM.intro.045.jpeg"},
      {"name": "YYTM.intro.046.jpeg"},
      {"name": "YYTM.intro.047.jpeg"},
      {"name": "YYTM.intro.048.jpeg"},
      {"name": "YYTM.intro.049.jpeg"}
    ]
  }, {
    "name": "1.8_Shiyuan_Studio", "contents": [
      {"name": "SY.001.jpeg"},
      {"name": "SY.002.jpeg"},
      {"name": "SY.003.jpeg"},
      {"name": "SY.004.jpeg"},
      {"name": "SY.005.jpeg"},
      {"name": "SY.006.jpeg"},
      {"name": "SY.007.jpeg"},
      {"name": "SY.008.jpeg"},
      {"name": "SY.009.jpeg"},
      {"name": "SY.010.jpeg"},
      {"name": "SY.011.jpeg"},
      {"name": "SY.012.jpeg"},
      {"name": "SY.013.jpeg"},
      {"name": "SY.014.jpeg"},
      {"name": "SY.015.jpeg"},
      {"name": "SY.016.jpeg"},
      {"name": "SY.017.jpeg"},
      {"name": "SY.018.jpeg"},
      {"name": "SY.019.jpeg"},
      {"name": "SY.020.jpeg"},
      {"name": "SY.021.jpeg"},
      {"name": "SY.022.jpeg"},
      {"name": "SY.023.jpeg"},
      {"name": "SY.024.jpeg"},
      {"name": "SY.025.jpeg"},
      {"name": "SY.026.jpeg"},
      {"name": "SY.027.jpeg"},
      {"name": "SY.028.jpeg"},
      {"name": "SY.029.jpeg"},
      {"name": "SY.030.jpeg"}
    ]
  }, {
    "name": "home", "contents": [
      {"name": "YYTM.intro.005.jpeg"},
      {"name": "YYTM.intro.009.jpeg"},
      {"name": "YYTM.intro.021.jpeg"},
      {"name": "YYTM.intro.035.jpeg"},
      {"name": "YYTM.intro.043.jpeg"},
      {"name": "YYTM.intro.046.jpeg"}
    ]
  }, {
    "name": "YYTM_intro", "contents": [
      {"name": "YYTM.intro.001.jpeg"},
      {"name": "YYTM.intro.050.jpeg"},
      {"name": "YYTM.intro.051.jpeg"},
      {"name": "YYTM.intro.052.jpeg"},
      {"name": "YYTM.intro.053.jpeg"},
      {"name": "YYTM.intro.054.jpeg"},
      {"name": "YYTM.intro.055.jpeg"},
      {"name": "YYTM.intro.056.jpeg"}
    ]
  }
]

class MainContent extends React.Component {
  previousY = 10000000;
  hideNavTimer;
  scrollListener = e => {
    let headerHeight = 80;
    const {scrollY} = window;
    const {show: {header: propsShow, nav: navShow}} = this.props;
    let hide = this.previousY < scrollY && scrollY > headerHeight;
    if (navShow && !this.hideNavTimer) {
      this.hideNavTimer = setTimeout(() => {
        this.hideNavTimer = undefined;
        this.props.dispatch(showNav(false));
      }, 200)
    }
    if (scrollY < 100 && !propsShow) {
      this.showHeader_(true);
      return;
    }
    if (Math.abs(this.previousY - scrollY) === headerHeight) return;
    this.previousY = scrollY;
    if (propsShow == !hide) return
    if (!hide) {
      this.showHeader_(true);
    } else {
      this.showHeaderDebounce(false);
    }
  };

  componentDidMount() {
    this.showHeaderDebounce = debounce(this.showHeader_, 20)
    window.addEventListener("scroll", this.scrollListener)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollListener)
  }

  showHeader_ = (show) => {
    this.props.dispatch(showHeader(show))
  }

  render() {
    let {menu} = this.props;
    let items = ImageInfos.filter(e => e.name.toLowerCase().indexOf(menu.toLowerCase().replace(/[ &-]/, '_')) != -1)
    let item = items[0] || {contents: []};
    return <div id='mainContent' className={`content-size-${item.contents.length}`}>
      {item.contents.map(img => <img key={img.name} src={`/res/${item.name}/${img.name}`} alt={item.name}
                                     loading='lazy'/>)}
    </div>
  }
}

export default connect(state => ({
  show: state.layout.show,
}))(MainContent)
