import React, {lazy} from 'react';
import {Route, Routes} from 'react-router';
import MainContent from "@/pages/MainContent";
import menu from "@/menu";
import Home from "@/pages/Home";

// const Home = lazy(() => import('@/pages/Home'))

let routeInfo = [
  {path: '/', element: <Home/>},
]
menu.forEach(e => {
  routeInfo.push({path: "/" + e.url, element: <MainContent menu={e.name}/>})
})

function getRouteObject(route) {
  if (!route || route.length == 0) return;
  return route.map(e => {
    return <Route path={e.path} key={e.path}
                  element={<React.Suspense fallback={<p>Loading...</p>}>{e.element}</React.Suspense>}>
      {getRouteObject(route.children)}
    </Route>
  })
}

export default function RouterRoot() {
  return <Routes>{getRouteObject(routeInfo)}</Routes>
}
